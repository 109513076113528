<template>
<div class="d-flex flex-wrap">
  <div :class="profile === 1 ? 'folderCard hoverFolder' : 'folderCard hoverFolder flex'" v-for="item in items" :key="item.id"  @click="$emit('openFolder', item)">
    <div class="header">
      <div class="top" v-if="profile === 1">
        <button class="btn_edit" @click="$emit('showModal', 'edit', item)">
          <img src="@/assets/image/icones/arquivos/edit.png" alt="edit" />
        </button>
        <div> 
          <span>M</span>
          <span v-if="checkProfile(item.trainingCategoryPermissions, 2)">F</span>
          <span v-if="checkProfile(item.trainingCategoryPermissions, 3)">V</span>
        </div>
      </div>
      <div class="containerFolder" @click="openFolder(item)">
        <h1>{{ item.name }}</h1>
      </div>
      <div class="footer d-flex justify-content-between align-items-center" v-if="profile === 1">
        <h1>{{ item.filesAll }} Arquivos </h1>
        <button class="mr-2" @click="$emit('deleteFolder', item)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#dc3545" class="bi bi-trash3" viewBox="0 0 16 16">
            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ['items'],
  computed: {
    profile() {
      return this.$store.getters.getUser.profile
    }
  },
  methods: {
    openFolder(item) {
      this.$store.commit('setRouterFolder', item.name)
      this.$router.push({name: 'arquivo', params: { id: item.id }})
    },
    checkProfile(data, item){
      return data.some((d) => d.profile === item)   
    }
  },
}
</script>

<style scoped>
.folderCard {
  height: 210px;
  width: 269px;
  background: #ffffff;
  border: 1px solid #3f88c1;
  box-sizing: border-box;
  border-radius: 12px;
  transition: 0.2s ease-in-out;
  margin: 0px 5px 24px 3px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;
}

button {
  outline: none;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.top span {
  background: #f5f1f1;
  padding: 4px 10px;
  color: #f38235;
  margin-right: 10px;
  border-radius: 6px;
}

.containerFolder {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerFolder h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #3f88c1;
  cursor: pointer;
  transition: .2s ease-in-out;
}

.containerFolder h1:hover {
  color: #f38235;
}

.footer h1 {
  margin: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #797984;
  opacity: 0.5;
}

.footer button {
  background: none;
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 40px;
}

.footer button:hover {
  background: #ebe3e498;
}

.btn_edit {
  cursor: pointer;
  height: 40px;
  width: 40px;
  border: none;
  background: none;
}

.hoverFolder:hover {
  color: #f38235 !important;
  transform: translateY(-15px);
  -webkit-box-shadow: 5px 7px 10px 1px rgba(0,0,0,0.39); 
  box-shadow: 5px 7px 10px 1px rgba(0,0,0,0.39);
}
@media screen and (max-width:767px){
  .folderCard{
    width: 100%;
  }
}
</style>
